<template>
  <div class="container">
    <span class="is-size-5"> Preferences </span> <br> <br><br>
    <div class="columns">
      <div class="column is-2">
        <article class="panel is-primary"> 
          <a
            class="panel-block "
            :class="{ 'is-active': currentTab === 'profile' }"
            @click="currentTab = 'profile'"
          > 
            <span class="panel-icon">
              <i
                class="mdi mdi-face-profile"
                aria-hidden="true"
              />
            </span>
            Profile
          </a>
          <a
            class="panel-block "
            :class="{ 'is-active': currentTab === 'token' }"
            @click="currentTab = 'token'"
          > 
            <span class="panel-icon">
              <i
                class="mdi mdi-key"
                aria-hidden="true"
              />
            </span>
            Tokens
          </a>
          <a
            class="panel-block "
            :class="{ 'is-active': currentTab === 'users' }"
            @click="currentTab = 'users'"
          >
            <span class="panel-icon">
              <i
                class="mdi mdi-account-group"
                aria-hidden="true"
              />
            </span>
            Users
          </a>
          <a
            class="panel-block "
            :class="{ 'is-active': currentTab === 'webhook' }"
            @click="currentTab = 'webhook'"
          >
            <span class="panel-icon">
              <i
                class="mdi mdi-webhook"
                aria-hidden="true"
              />
            </span>
            Web-hooks
          </a>
          <!-- <a class="panel-block">
            <span class="panel-icon">
              <i
                class="fas fa-book"
                aria-hidden="true"
              />
            </span>
            Profile
          </a> -->
        </article>
      </div>
      <div class="column">
        <token-view v-if="currentTab === 'token'" />
        <user-management v-if="currentTab === 'users'" />
        <webhook-setting v-if="currentTab === 'webhook'" />
        <profile-view v-if="currentTab === 'profile'" />
      </div>
    </div>
  </div>
</template>

<script>
import TokenView from "./components/TokenView"
import UserManagement from "./components/UserManagment"
import WebhookSetting from "./components/WebhookSetting"
import ProfileView from "./components/ProfileView"

export default {
    components: {
      TokenView,
      UserManagement,
      WebhookSetting,
      ProfileView
    },
    data() {
        return {
          currentTab: 'profile'
        }
    }
}
</script>

<style>

</style>