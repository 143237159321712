<template>
  <b-modal
    :active.sync="dialog"
    scroll="keep"
    :can-cancel="false"
    class="document-dialog"
    :style="{ width: $responsive.width + 'px' }"
  >
    <div
      class=" dialog modal-card"
      style="width: auto"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="userObj == null">New User </span><span v-else>Edit User </span>
        </p>
        <b-button
          class="button is-pulled-right is-white"
          type="button"
          icon-left="close"
          rounded
          @click="handleClose()"
        />
      </header>
      <section class="modal-card-body">
        <b-field label="First Name">
          <b-input v-model="user.first_name" />
        </b-field>
        <b-field label="Last Name">
          <b-input v-model="user.last_name" />
        </b-field>
        <b-field label="Email Address">
          <b-input
            v-model="user.email"
            type="email"
          />
        </b-field>
        <b-field
          v-if="user !=null && user.role_label != 'Owner'"
          label="Role"
          expanded
        >
          <b-select
            v-model="user.role"
            placeholder="Select a role"
          >
            <option
              v-for="role in userRoles"
              :key="role.id"
              :value="role.id"
            >
              {{ role.label }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <div class="level action-buttons">
          <div class="level-left">
            <b-button
              v-if="user.is_active"
              class="button"
              type="button is-danger"
              rounded
              @click="deleteUser()"
            >
              Disable
            </b-button>
            <b-button
              v-if="user.is_active == false"
              class="button"
              type="button "
              rounded
              @click="enableUser()"
            >
              Enable
            </b-button>
          </div>
          <div class="level-right">
            <b-button
              class="button is-primary"
              :loading="isSavingProgress"
              icon-left="content-save"
              rounded
              @click="save()"
            >
              Save
            </b-button>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import isValidEmail from 'is-valid-email';
import axios from 'axios';

export default {
  props: {
    visibility: {
      default: false,
      type: Boolean,
    },
    organizationId: {
      type: [Number],
      required: true,
    },
    userObj: {
      type: Object,
      required: false,
      default: function() { return {id : -1,} }
    },
  },
  data() {
    return {
      dialog: false,
      isSavingProgress: false,
      isDeletingProgress: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        organization_id: -1,
        role: 3,
        time_zone: 'Pacific/Auckland',
        id: -1,
      },  
      userRoles: [{
        id: 2,
        label: 'Manager - Can administer users & documents'
      },{
        id: 3,
        label: 'Member - Can access and change documents'
      }]
    };
  },
  watch: {
    visibility(value) {
      this.dialog = value;
    },
     userObj(value) {
       if(value == null) {
         this.user = {
            id: -1,
            first_name: "",
            last_name: "",
            email: "",
            organization_id: this.organizationId,
            time_zone: 'Pacific/Auckland',
          }
       }else{
          this.user = value;
       }
      
    }
  },

  methods: {
    handleClose() {
      this.dialog = false;
      this.$emit('handleUserEditorDialogClose');
    },
    save() {

      if(this.user.first_name == "") {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'First name is mandatory.',
        });
        return;
      }
      if(this.user.last_name == "") {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Last name is mandatory.',
        });
        return;
      }
      if(this.user.email == "") {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Email address is mandatory.',
        });
        return;
      }
      
      if(this.user.id == undefined || this.user.id == -1) {
        this.createUser();
      }else{
        this.EditUser();
      }
    },
    createUser() {
      this.isLoading = true;
      let url =
        '/users';
        axios
        .post(url, this.user)
        .then((response) => {
          this.isLoading = false;
          this.$buefy.toast.open('Saved');
          this.$emit('handleUserEditorDialogClose');
        })
        .catch((e) => {
          if(e.response.status == 405){
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please check your permissions.',
            });
          }else if(e.response.status == 400){
            if(e.response.data.email != undefined) {
              this.$buefy.toast.open({
                type: 'is-danger',
                message: 'Email address already exist',
              });
            } else{
              this.$buefy.toast.open({
                type: 'is-danger',
                message: 'Please validate the input',
              });
            }

          }else{
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please try again later.',
            });
          }
          this.isLoading = false;
        });
    },
    EditUser() {
      this.isLoading = true;
      let url =
        '/users/'+this.user.id;
        axios
        .patch(url, this.user)
        .then((response) => {
          this.isLoading = false;
          this.$buefy.toast.open('Saved');
          this.$emit('handleUserEditorDialogClose');
        })
        .catch((e) => {
          if(e.response.status == 405){
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please check your permissions.',
            });
          }else{
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please try again later.',
            });
          }
          this.isLoading = false;

        });
    },
    deleteUser() {
      this.isLoading = true;
      let url =
        '/users/'+this.user.id;
        axios
        .delete(url)
        .then((response) => {
          this.isLoading = false;
          this.$buefy.toast.open('User disabled');
          this.$emit('handleUserEditorDialogClose');
        })
        .catch((e) => {
          if(e.response.status == 405){
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please check your permissions.',
            });
          }else{
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please try again later.',
            });
          }
          this.isLoading = false;

        });
    },
    enableUser() {
      this.isLoading = true;
      let url =
        '/users/'+this.user.id +'/enable';
        axios
        .post(url)
        .then((response) => {
          this.isLoading = false;
          this.$buefy.toast.open('User enabled');
          this.$emit('handleUserEditorDialogClose');
        })
        .catch((e) => {
          if(e.response.status == 405){
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please check your permissions.',
            });
          }else{
            this.$buefy.toast.open({
              type: 'is-danger',
              message: ' Please try again later.',
            });
          }
          this.isLoading = false;

        });
    },
    validateEmailAddress(valueToCheck) {
      if (!isValidEmail(valueToCheck)) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Email address is not valid',
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.document-dialog {
  z-index: 1;
}

.modal {
  z-index: 100;
}
.document-dialog > .modal-background {
  background-color: transparent;
}
.modal-content {
  box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -moz-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -webkit-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
}

.modal-card-head,
.modal-card-foot {
  background-color: #ffffff !important;
}
.modal-card-foot {
  padding: 10px;
}

.modal-card-foot {
  border-top: none;
}

.action-buttons {
  width: 100%;
}
.signature-pad {
  border: 1px solid #cccccc;
}
/* .modal-background {
  margin: 0;
  height: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
} */
</style>
