var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("span", { staticClass: "is-size-5" }, [_vm._v(" Preferences ")]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-2" }, [
        _c("article", { staticClass: "panel is-primary" }, [
          _c(
            "a",
            {
              staticClass: "panel-block ",
              class: { "is-active": _vm.currentTab === "profile" },
              on: {
                click: function($event) {
                  _vm.currentTab = "profile"
                }
              }
            },
            [_vm._m(0), _vm._v(" Profile ")]
          ),
          _c(
            "a",
            {
              staticClass: "panel-block ",
              class: { "is-active": _vm.currentTab === "token" },
              on: {
                click: function($event) {
                  _vm.currentTab = "token"
                }
              }
            },
            [_vm._m(1), _vm._v(" Tokens ")]
          ),
          _c(
            "a",
            {
              staticClass: "panel-block ",
              class: { "is-active": _vm.currentTab === "users" },
              on: {
                click: function($event) {
                  _vm.currentTab = "users"
                }
              }
            },
            [_vm._m(2), _vm._v(" Users ")]
          ),
          _c(
            "a",
            {
              staticClass: "panel-block ",
              class: { "is-active": _vm.currentTab === "webhook" },
              on: {
                click: function($event) {
                  _vm.currentTab = "webhook"
                }
              }
            },
            [_vm._m(3), _vm._v(" Web-hooks ")]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.currentTab === "token" ? _c("token-view") : _vm._e(),
          _vm.currentTab === "users" ? _c("user-management") : _vm._e(),
          _vm.currentTab === "webhook" ? _c("webhook-setting") : _vm._e(),
          _vm.currentTab === "profile" ? _c("profile-view") : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "panel-icon" }, [
      _c("i", {
        staticClass: "mdi mdi-face-profile",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "panel-icon" }, [
      _c("i", { staticClass: "mdi mdi-key", attrs: { "aria-hidden": "true" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "panel-icon" }, [
      _c("i", {
        staticClass: "mdi mdi-account-group",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "panel-icon" }, [
      _c("i", {
        staticClass: "mdi mdi-webhook",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }