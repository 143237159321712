<template>
  <div class="card">
    <div class="card-content">
      <h3 class="subtitle is-4">
        Profile
      </h3>
      <div class="columns ">
        <div class="column is-8 ">
          <b-field label="First Name">
            <b-input
              v-model="firstName"
              placeholder=""
            />
          </b-field>
          <b-field label="Last Name">
            <b-input
              v-model="lastName"
              placeholder=""
            />
          </b-field>

          <wysiwyg v-model="mailSignature" />
          <br>
          <button
            class="button is-primary is-pulled-right"
            @click="onSubmit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseUserInfo from "@/views/auth/BaseUserInfo";

export default {
  extends: BaseUserInfo,
  props: {},
  data() {
    return {
      mailSignature: "",
      firstName: "",
      lastName:"",
      loading: true
    };
  },
  computed: {
    organizationId() {
      return this.$store.state.user.organization.id;
    }
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    getUserProfile() {
      if(this.userInfo == undefined) return;
      axios
        .get("/users/" + this.userInfo.id )
        .then(response => {
          if (response.status === 200) {
            this.firstName =
              response.data.first_name;
            this.lastName =
              response.data.last_name;
            this.mailSignature =
              response.data.mail_signature;
          }
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.handleErrorMessages(e.response.data);
          }
        });
    },
    onSubmit() {
      const _data = {
        first_name: this.firstName,
        last_name: this.lastName,
        mail_signature: this.mailSignature
      };
      axios
        .patch(
          "/users/"  + this.userInfo.id ,
          _data
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.handleErrorMessages(e.response.data);
          }
        });
    },
    userInfoUpdate(){
      this.getUserProfile();
    }
  }
};
</script>

<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>