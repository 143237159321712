<template>
  <div class="card">
    <div class="card-content">
      <h3 class="subtitle is-4">
        Web-hook Configuration
      </h3>
      <div class="columns ">
        <div class="column is-8 ">
          <p>You can use webhook to send data to your server once the document signature is complete.</p>

          <b-field label="Server Url">
            <b-input
              v-model="form.remoteServerUrl"
              placeholder="https://example.com/data"
            />
          </b-field>
          <b-field label="Username">
            <b-input
              v-model="form.auth.username"
              type="text"
            />
          </b-field>
          <b-field label="Password">
            <b-input
              v-model="form.auth.password"
              type="password"
            />
          </b-field>
          <button
            class="button is-primary is-pulled-right"
            @click="onSubmit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseUserInfo from "@/views/auth/BaseUserInfo";

export default {
  extends: BaseUserInfo,
  props: {},
  data() {
    return {
      form: {
        remoteServerUrl: "",
        auth: {
          type: "basic",
          username: "",
          password: ""
        }
      },
      loading: true
    };
  },
  computed: {
    organizationId() {
      return this.$store.state.user.organization.id;
    }
  },
  mounted() {
    this.getHooks();
  },
  methods: {
    getHooks() {
      axios
        .get("/organizations/" + this.userInfo.organization.id + "/preferences")
        .then(response => {
          if (response.status === 200) {
            this.form.remoteServerUrl =
              response.data.preferences.document_web_hook_url;
            this.form.auth.username =
              response.data.preferences.document_web_hook_username;
            this.form.auth.password =
              response.data.preferences.document_web_hook_passsword;
          }
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.handleErrorMessages(e.response.data);
            this.getFormView();
          }
        });
    },
    onSubmit() {
      const _data = {
        document_webhook_url: this.form.remoteServerUrl,
        document_webhook_username: this.form.auth.username,
        document_webhook_password: this.form.auth.password
      };
      axios
        .patch(
          "/organizations/" + this.userInfo.organization.id + "/preferences",
          _data
        )
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(e => {
          if (e.response.status === 400) {
            this.handleErrorMessages(e.response.data);
            this.getFormView();
          }
        });
    }
  }
};
</script>

<style>
</style>