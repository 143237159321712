<template>
  <div class="content">
    <div
      class="card"
    >
      <div class="card-content">
        <h3>API token</h3>
        <p> You can use API to update your forms data. Check the developer documentation for using API. </p>
        <p>
          Token
          <b-button
            type="is-default"
            style="float: right;"
            icon-right="refresh"
            @click="resetTokenDialog"
          />
        </p>
        <br>
        <code
          v-if="token !=null && token.key != null"
          style="font-size:18px;"
        > {{ token.key }} </code> <small
          v-if="token !=null && token.created_at != null"
          style="float: right;"
        > {{ token.created_at | localTime }} </small>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import UserInfoMixin from "@/views/auth/UserInfoMixin";

export default {
  mixins: [UserInfoMixin],
  props: {
    userId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    return {
      token: null
    };
  },
  watch: {
    userInfo: function() {
      this.getTokenInfo();
    }
  },
  mounted() {
    this.getTokenInfo();
  },
  methods: {
    getTokenInfo() {
      if (this.userInfo == null) return;
      axios
        .get("/users/" + this.userInfo.id + "/tokens")
        .then(response => {
          this.token = response.data;
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          this.$buefy.toast.open({
                    message: 'Try again later',
                    type: 'is-error'
                })
        });
    },
    refreshToken() {
      if (this.userInfo == null) return;
      axios
        .post("/users/" + this.userInfo.id + "/reset_token")
        .then(response => {
          this.token = response.data;
          this.loading = false;
          this.$buefy.toast.open({
                    message: 'Refreshed Token',
                    type: 'is-success'
                })
        })
        .catch(e => {
          this.loading = false;
          this.$buefy.toast.open({
                    message: 'Try again later',
                    type: 'is-error'
                })
        });
    },
    resetTokenDialog() {
      this.$buefy.dialog.confirm({
                message: "This will revoke access to existing keys. Continue?",
                onConfirm: () => { this.refreshToken(); }
            })
    }
  }
};
</script>

