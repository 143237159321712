<template>
  <div class=" card">
    <div class="card-content">
      <div class="columns  multiline is-10 is-centered">
        <div class="column ">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h3 class="subtitle is-4">
                  Users
                </h3>
              </div>
            </div>
            <div class="level-right">
              <b-button
                type="is-primary"
                @click="showEditUserDialog(null)"
              >
                New User
              </b-button>
            </div>
          </div>
          <table class="table is-hoverable is-fullwidth">
            <thead>
              <th> Name </th>
              <th> Role </th>
              <th> Status </th>
              <th> Created </th>
              <th />
            </thead>
            <tbody>
              <tr
                v-for="user in users"
                :key="user.id"
              >
                <td>
                  {{ user.first_name }}
                </td>
                <td>
                  {{ user.role_label }}
                </td>
                <td>
                  <span
                    class="tag"
                  >{{ userStatus(user) }}</span>
                </td>
                <td>{{ user.created_at | localTime }}</td>
                <td>
                  <b-dropdown aria-role="list">
                    <button
                      slot="trigger"
                      class="button is-text"
                    >
                      <b-icon
                        icon="dots-vertical"
                        class="has-text-grey"
                      />
                    </button>
                    <b-dropdown-item
                      aria-role="listitem"
                      @click="showEditUserDialog(user)"
                    >
                      <b-icon
                        icon="share"
                        class="has-text-grey"
                      /> Edit
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <user-dialog
      :visibility="isUserEditorDialogShown"
      :user-obj="currentUserToEdit"
      :organization-id="organizationId"
      @handleUserEditorDialogClose="handleUserEditorDialogClose"
    />
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import BaseUserInfo from '@/views/auth/BaseUserInfo';
import UserDialog from '@/views/users/components/UserDialog'

import { eventHub } from "@/commons/event-bus.js";

export default {
  components: {
    UserDialog
  },
  extends: BaseUserInfo,
  data() {
    return {
      users: {},
      isLoading: true,
      isUserEditorDialogShown: false,
      currentUserToEdit: null
    };
  },
  computed: {
    organizationId(){
      return this.$store.state.user.organization.id;
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    userStatus(user){
      if(user.is_verified) {
        if(user.is_active == true){
          return 'Active'
        }else{
          return 'Disabled'
        }
      }else{
        return 'Not verified'
      }

    },
    showEditUserDialog(user) {
      this.currentUserToEdit = user;
      this.isUserEditorDialogShown = true;
    },
    handleUserEditorDialogClose() {
      this.isUserEditorDialogShown = false;
      this.currentUserToEdit = null;
      this.getUsers();
    },
    getUsers() {
      if (this.$store.state.user == null) {
        return;
      }
      if(this.searchText != '') {
        this.isSearchLoading = true;
      }else{
        this.isLoading = true;
      }
      

      var url =
        '/users';

      if (this.searchText != null && this.searchText != '') {
        url += '?search=' + this.searchText;
      }
      axios
        .get(url)
        .then((response) => {
          if(this.searchText != '') {
            this.isSearchLoading = false;
          }else{
            this.isLoading = false;
          }
          this.users = response.data.results;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
            if(this.searchText != '') {
              this.isSearchLoading = false;
            }else{
              this.isLoading = false;
            }
        });
    },
  }

}
</script>

<style>

</style>