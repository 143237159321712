var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-content" }, [
      _c("h3", { staticClass: "subtitle is-4" }, [
        _vm._v(" Web-hook Configuration ")
      ]),
      _c("div", { staticClass: "columns " }, [
        _c(
          "div",
          { staticClass: "column is-8 " },
          [
            _c("p", [
              _vm._v(
                "You can use webhook to send data to your server once the document signature is complete."
              )
            ]),
            _c(
              "b-field",
              { attrs: { label: "Server Url" } },
              [
                _c("b-input", {
                  attrs: { placeholder: "https://example.com/data" },
                  model: {
                    value: _vm.form.remoteServerUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "remoteServerUrl", $$v)
                    },
                    expression: "form.remoteServerUrl"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Username" } },
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.form.auth.username,
                    callback: function($$v) {
                      _vm.$set(_vm.form.auth, "username", $$v)
                    },
                    expression: "form.auth.username"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Password" } },
              [
                _c("b-input", {
                  attrs: { type: "password" },
                  model: {
                    value: _vm.form.auth.password,
                    callback: function($$v) {
                      _vm.$set(_vm.form.auth, "password", $$v)
                    },
                    expression: "form.auth.password"
                  }
                })
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "button is-primary is-pulled-right",
                on: { click: _vm.onSubmit }
              },
              [_vm._v(" Save ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }