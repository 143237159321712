var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "document-dialog",
      style: { width: _vm.$responsive.width + "px" },
      attrs: { active: _vm.dialog, scroll: "keep", "can-cancel": false },
      on: {
        "update:active": function($event) {
          _vm.dialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: " dialog modal-card", staticStyle: { width: "auto" } },
        [
          _c(
            "header",
            { staticClass: "modal-card-head" },
            [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm.userObj == null
                  ? _c("span", [_vm._v("New User ")])
                  : _c("span", [_vm._v("Edit User ")])
              ]),
              _c("b-button", {
                staticClass: "button is-pulled-right is-white",
                attrs: { type: "button", "icon-left": "close", rounded: "" },
                on: {
                  click: function($event) {
                    return _vm.handleClose()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c(
                "b-field",
                { attrs: { label: "First Name" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.user.first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "first_name", $$v)
                      },
                      expression: "user.first_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Last Name" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.user.last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "last_name", $$v)
                      },
                      expression: "user.last_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Email Address" } },
                [
                  _c("b-input", {
                    attrs: { type: "email" },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  })
                ],
                1
              ),
              _vm.user != null && _vm.user.role_label != "Owner"
                ? _c(
                    "b-field",
                    { attrs: { label: "Role", expanded: "" } },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: { placeholder: "Select a role" },
                          model: {
                            value: _vm.user.role,
                            callback: function($$v) {
                              _vm.$set(_vm.user, "role", $$v)
                            },
                            expression: "user.role"
                          }
                        },
                        _vm._l(_vm.userRoles, function(role) {
                          return _c(
                            "option",
                            { key: role.id, domProps: { value: role.id } },
                            [_vm._v(" " + _vm._s(role.label) + " ")]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "level action-buttons" }, [
              _c(
                "div",
                { staticClass: "level-left" },
                [
                  _vm.user.is_active
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button is-danger", rounded: "" },
                          on: {
                            click: function($event) {
                              return _vm.deleteUser()
                            }
                          }
                        },
                        [_vm._v(" Disable ")]
                      )
                    : _vm._e(),
                  _vm.user.is_active == false
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button ", rounded: "" },
                          on: {
                            click: function($event) {
                              return _vm.enableUser()
                            }
                          }
                        },
                        [_vm._v(" Enable ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "level-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        loading: _vm.isSavingProgress,
                        "icon-left": "content-save",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }