var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-content" }, [
        _c("h3", [_vm._v("API token")]),
        _c("p", [
          _vm._v(
            " You can use API to update your forms data. Check the developer documentation for using API. "
          )
        ]),
        _c(
          "p",
          [
            _vm._v(" Token "),
            _c("b-button", {
              staticStyle: { float: "right" },
              attrs: { type: "is-default", "icon-right": "refresh" },
              on: { click: _vm.resetTokenDialog }
            })
          ],
          1
        ),
        _c("br"),
        _vm.token != null && _vm.token.key != null
          ? _c("code", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(" " + _vm._s(_vm.token.key) + " ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.token != null && _vm.token.created_at != null
          ? _c("small", { staticStyle: { float: "right" } }, [
              _vm._v(
                " " + _vm._s(_vm._f("localTime")(_vm.token.created_at)) + " "
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }