var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-content" }, [
      _c("h3", { staticClass: "subtitle is-4" }, [_vm._v(" Profile ")]),
      _c("div", { staticClass: "columns " }, [
        _c(
          "div",
          { staticClass: "column is-8 " },
          [
            _c(
              "b-field",
              { attrs: { label: "First Name" } },
              [
                _c("b-input", {
                  attrs: { placeholder: "" },
                  model: {
                    value: _vm.firstName,
                    callback: function($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Last Name" } },
              [
                _c("b-input", {
                  attrs: { placeholder: "" },
                  model: {
                    value: _vm.lastName,
                    callback: function($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName"
                  }
                })
              ],
              1
            ),
            _c("wysiwyg", {
              model: {
                value: _vm.mailSignature,
                callback: function($$v) {
                  _vm.mailSignature = $$v
                },
                expression: "mailSignature"
              }
            }),
            _c("br"),
            _c(
              "button",
              {
                staticClass: "button is-primary is-pulled-right",
                on: { click: _vm.onSubmit }
              },
              [_vm._v(" Save ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }