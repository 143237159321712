var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: " card" },
    [
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "columns  multiline is-10 is-centered" }, [
          _c("div", { staticClass: "column " }, [
            _c("div", { staticClass: "level" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "level-right" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.showEditUserDialog(null)
                        }
                      }
                    },
                    [_vm._v(" New User ")]
                  )
                ],
                1
              )
            ]),
            _c("table", { staticClass: "table is-hoverable is-fullwidth" }, [
              _vm._m(1),
              _c(
                "tbody",
                _vm._l(_vm.users, function(user) {
                  return _c("tr", { key: user.id }, [
                    _c("td", [_vm._v(" " + _vm._s(user.first_name) + " ")]),
                    _c("td", [_vm._v(" " + _vm._s(user.role_label) + " ")]),
                    _c("td", [
                      _c("span", { staticClass: "tag" }, [
                        _vm._v(_vm._s(_vm.userStatus(user)))
                      ])
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm._f("localTime")(user.created_at)))
                    ]),
                    _c(
                      "td",
                      [
                        _c(
                          "b-dropdown",
                          { attrs: { "aria-role": "list" } },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button is-text",
                                attrs: { slot: "trigger" },
                                slot: "trigger"
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "has-text-grey",
                                  attrs: { icon: "dots-vertical" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { "aria-role": "listitem" },
                                on: {
                                  click: function($event) {
                                    return _vm.showEditUserDialog(user)
                                  }
                                }
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "has-text-grey",
                                  attrs: { icon: "share" }
                                }),
                                _vm._v(" Edit ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ])
          ])
        ])
      ]),
      _c("user-dialog", {
        attrs: {
          visibility: _vm.isUserEditorDialogShown,
          "user-obj": _vm.currentUserToEdit,
          "organization-id": _vm.organizationId
        },
        on: { handleUserEditorDialogClose: _vm.handleUserEditorDialogClose }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h3", { staticClass: "subtitle is-4" }, [_vm._v(" Users ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v(" Name ")]),
      _c("th", [_vm._v(" Role ")]),
      _c("th", [_vm._v(" Status ")]),
      _c("th", [_vm._v(" Created ")]),
      _c("th")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }